/* eslint-disable no-underscore-dangle */
/* eslint-disable no-restricted-syntax */
import { GenerateXlsx } from './generate-xlsx';

export class GenerateFileReportDataOfxXlsx extends GenerateXlsx {
    insertHeader() {
        super.insertLineStyled({
            firstColumn: 'DTA',
            secondColumn: 'TIPO',
            thirdColumn: 'DESCRIÇÃO',
            fourthColumn: 'OPERATION_ID',
            fifthColumn: 'CHECK_NUMBER',
            sixthColumn: 'VALOR',
            seventhColumn: 'DESCRIÇÃO',

        }, {
            bold: true,
            horizontalAlignment: 'center',
            verticalAlignment: 'center',
        });
    }

    insertFilialHeader({ code: bankCode, bankName, account }) {
        this.insertLineStyled({
            firstColumn: 'Código banco',
            secondColumn: bankCode,
            thirdColumn: 'Banco',
            fourthColumn: bankName,
            fifthColumn: 'Conta',
            sixthColumn: account,
        }, {
            fontSize: 11,
            bold: true,
        });
    }

    insertFilialData(data) {
        const [firstData] = data;
        this.insertFilialHeader(firstData);
        this.insertHeader();
        for (const item of data) {
            super.insertLine({
                firstColumn: item.dta,
                secondColumn: item.type,
                thirdColumn: item.description,
                fourthColumn: item.operationId,
                fifthColumn: item.checkNumber,
                sixthColumn: item.value,
                seventhColumn: item.comment,
            });
        }
        this.insertBlankLine();
        this.insertBlankLine();
    }

    emptyFile() {
        this._fileContent = [];
    }

    insertTitle(
        title,
    ) {
        this.insertLineStyled({
            firstColumn: title,
        }, {
            fontSize: 11,
            bold: true,
        });
    }
}
