<template>
  <b-modal
    ref="modal"
    size="lg"
    hide-footer
  >
    <update-form
      :data="request"
      @click-save-button="handleClickSaveButton"
    />
  </b-modal>
</template>

<script>
import { BModal } from 'bootstrap-vue';

import UpdateForm from './Form/Update.vue';

import ConciliationAnimaService from '@/service/conciliation/csv';
import ConciliationOfxService from '@/service/conciliation/ofx';

const servicesByTypeId = {
    1: ConciliationAnimaService,
    2: ConciliationOfxService,
};

export default {
    components: {
        UpdateForm,
        BModal,
    },

    props: {
        allData: {
            default: () => [],
            type: Array,
        },
    },

    data() {
        return {
            request: {
                department: null,
                source: '',
                target: '',
                comment: '',
            },
        };
    },

    methods: {
        async handleClickSaveButton() {
            const dataFilteredByConfirm = this.allData.filter(({ confirm }) => confirm);

            const promises = dataFilteredByConfirm.map(item => {
                const service = servicesByTypeId[item.type_id];

                const {
                    source, target, comment, department,
                } = this.request;

                return service.update({
                    type_id: item.type_id,
                    id: item.id,
                    source,
                    target,
                    comment,
                    department,

                });
            });
            this.callLoading(true);
            const response = await Promise.all(promises);
            this.callLoading(false);

            const requestResolvedWithErrors = response.find(item => item.status !== 200);

            this.$refs.modal.hide();

            if (requestResolvedWithErrors) {
                this.modalError('Faça novamente, houve erro em alguns lançamentos');
                return;
            }

            this.$emit('updated');
            this.modalSuccess('Alteração realizada com sucesso');
        },

        showModal() {
            this.$refs.modal.show();
        },
    },
};
</script>
