export class GenerateXlsx {
    _fileContent = []

    get fileContent() {
        // eslint-disable-next-line no-underscore-dangle
        return this._fileContent;
    }

    insertBlankLine() {
        this.fileContent.push({});
    }

    insertLine(line) {
        this.fileContent.push(line);
    }

    insertBoldLine(line) {
        this.fileContent.push({ ...line });
    }

    insertLineStyled(line, style) {
        this.fileContent.push({ ...line, style });
    }
}
