<template>
  <Layout
    :fields="fields"
    :data="data"
    title="Anima"
    @delete-file="deleteFile"
    @finish-update="$emit('finish-update')"
  />
</template>

<script>
import Layout from './Layout/Layout.vue';

import ConciliationAnimaService from '@/service/conciliation/csv';

export default {
    components: {
        Layout,
    },

    props: {
        data: {
            type: Object,
            default: () => ({ data: [] }),
        },
    },

    data() {
        return {
            fields: [
                { key: 'confirm', label: '' },
                { key: 'lanc_date', label: 'Lançamento' },
                { key: 'name', label: 'Cliente' },
                { key: 'complement', label: 'Descrição' },
                { key: 'event_description', label: 'Descrição do Evento' },
                { key: 'lanc_value', label: 'Valor' },
                { key: 'counterparty', label: 'contraparte' },
                { key: 'departmentName', label: 'departamento' },
            ],
        };
    },

    methods: {
        async deleteFile(fileId) {
            const isConfirmed = await this.confirmAnAction('Deseja realmente apagar o arquivo?');

            if (!isConfirmed) return;

            const { status } = await ConciliationAnimaService.deleteFile(fileId);

            this.$emit('deleted-file', status === 200);
        },
    },
};
</script>
