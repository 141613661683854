<script>
import { FileBuilderXlsx } from '../files/builder';

import { GenerateFileReportDataOfxXlsx } from '../files/generate-ofx-data';
import { GenerateFileReportDataXlsx } from '../files/generate-anima-data';

import generateAccountName from '../utils/generate-account-name';

import {
    formatDate,
} from '@/utils';

export default {
    methods: {
        groupAnima(data) {
            return data.reduce((acc, item) => {
                const { bank_accounts: bankAccount } = item;
                acc[bankAccount.bank] = acc[bankAccount.bank] || {};
                acc[bankAccount.bank][bankAccount.code] = acc[bankAccount.bank][bankAccount.code] || [];

                acc[bankAccount.bank][bankAccount.code].push({
                    filial: bankAccount.branch_code,
                    bank: bankAccount.code,
                    bankName: bankAccount.bank,
                    date: formatDate(item.lanc_date),
                    event: item.event_description,
                    complement: item.complement,
                    value: +item.lanc_value,
                    lancCode: item.lanc_cont_id,
                    description: item.comments,
                });

                return acc;
            }, {});
        },

        groupOfx(data) {
            return data.reduce((acc, item) => {
                const { header_ofxes: { bank_accounts: bankAccount } } = item;
                acc[bankAccount.bank] = acc[bankAccount.bank] || {};
                acc[bankAccount.bank][bankAccount.current_account] = acc[bankAccount.bank][bankAccount.current_account] || [];
                acc[bankAccount.bank][bankAccount.current_account].push({
                    dta: formatDate(item.date),
                    type: item.type,
                    description: item.description,
                    operationId: item.fit_id,
                    checkNumber: item.check_number,
                    value: +item.value,
                    comment: item.comment,
                    code: bankAccount.code,
                    bankName: bankAccount.bank,
                    account: generateAccountName(bankAccount.current_account, bankAccount.branch_code, ''),
                });

                return acc;
            }, {});
        },

        generateAnimaLines(data, title) {
            const animaAggrupped = this.groupAnima(data);

            const generateFileReportDataXlsx = new GenerateFileReportDataXlsx();
            generateFileReportDataXlsx.insertTitle(`${title}`);

            Object.entries(animaAggrupped).forEach(([, bankCode]) => Object.values(bankCode).forEach(item => generateFileReportDataXlsx.insertFilialData(item)));

            generateFileReportDataXlsx.finish();

            return generateFileReportDataXlsx;
        },

        generateOfxLines(data, title) {
            const ofxAggrupped = this.groupOfx(data);

            const generateFileReportDataOfxXlsx = new GenerateFileReportDataOfxXlsx();
            generateFileReportDataOfxXlsx.insertTitle(`${title}`);

            Object.values(ofxAggrupped).forEach(objectValue => Object.values(objectValue).forEach(item => generateFileReportDataOfxXlsx.insertFilialData(item)));

            return generateFileReportDataOfxXlsx;
        },

        async generateFile({ anima, ofx }, title, fileTitle) {
            const { fileContent: animaLines } = this.generateAnimaLines(anima, title.anima);
            const { fileContent: ofxLines } = this.generateOfxLines(ofx, title.ofx);

            const fileGenerator = new FileBuilderXlsx([
                ...animaLines, ...ofxLines,
            ], fileTitle);

            (await fileGenerator.build()).download();
        },
    },
};
</script>
