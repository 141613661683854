<template>
  <b-container fluid>
    <ImportFile />
    <b-row>
      <b-col md="12">

        <b-form>
          <b-card>

            <b-row>
              <b-col md="3">
                <b-button
                  v-b-modal="'modal-scrollable'"
                  class="mb-2"
                  variant="primary"
                >
                  Carregar Informacoes
                </b-button>
              </b-col>

              <b-col
                md="12"
                lg="9"
                class="justify-content-start d-flex justify-content-lg-end "
              >
                <div>

                  <b-button
                    variant="primary"
                    class="d-md-block d-lg-inline-block"
                    @click="completedFile"
                  >
                    Baixar Dados Conciliados
                  </b-button>

                  <b-button
                    variant="primary"
                    class="ml-lg-1 ml-md-0 my-md-2 my-lg-0 d-md-block d-lg-inline-block my-2"
                    @click="uncompletedFile"
                  >
                    Baixar Dados Não Conciliados
                  </b-button>
                </div>
              </b-col>

            </b-row>

            <Form
              v-if="banks"
              :banks="banks"
              :data="request"
              :disabled="true"
              :available-files="availableFiles"
              @send="getAllConciliations"
            />

          </b-card>
        </b-form>

      </b-col>

      <b-col
        md="7"
      >
        <anima
          v-if="anima.data.length"
          :data="anima"
          @deleted-file="deletedFile"
          @finish-update="getAllConciliations"
        />
      </b-col>

      <b-col
        md="5"
      >
        <ofx
          v-if="ofx.data.length"
          :data="ofx"
          :bank="request.bank"
          @finish-update="getAllConciliations"
          @deleted-file="deletedFile"
        />
      </b-col>

      <update-many
        ref="updateMany"
        :all-data="[
          ...anima.data,
          ...ofx.data
        ]"
        @updated="getAllConciliations"
      />

      <b-col
        v-if="anima.data.length || ofx.data.length || conciliated.conciliations.length"
        md="12"
      >
        <ActionButtons
          class="mb-2"
          :disabled-finish="!!(anima.data.length || ofx.data.length)"
          :disabled-auto-conciliation="!validateAutoConciliation()"
          :disabled-conciliation="!validateConciliation()"
          @auto-conciliation="autoConciliation"
          @conciliation="conciliate"
          @download-not-conciliated="uncompletedFile"
          @download-conciliated="completedFile"
          @update-many="showUpdateMany"
          @finish="finish"
        />
      </b-col>

      <b-col
        v-if="conciliated.conciliations.length"
        md="12"
      >
        <Conciliation
          :data="conciliated.conciliations"
          :can-remove="true"
          @removed-item="getAllConciliations"
        />
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import {
    BRow,
    BCol,
    BContainer,
    BButton,
    BForm,
    BCard,
} from 'bootstrap-vue';

import '@validations';

import Anima from './components/Anima.vue';
import Ofx from './components/Ofx.vue';
import Conciliation from './components/Conciliation.vue';

import ActionButtons from './components/ActionButtons.vue';

import Form from './components/Form/Form.vue';
import ImportFile from './components/Form/ImportFile.vue';

import ConciliationOfxService from '@/service/conciliation/ofx';
import ConciliationAnimaService from '@/service/conciliation/csv';
import ConciliationService from '@/service/conciliation';
import BankService from '@/service/bank';

import UpdateMany from './components/UpdateMany.vue';

import DownloadFile from './mixins/DownloadFile.vue';

import types from './enums/types';

export default {
    components: {
        ActionButtons,
        Anima,
        BButton,
        BCard,
        BContainer,
        BRow,
        BCol,
        BForm,
        Conciliation,
        Ofx,
        ImportFile,
        Form,
        UpdateMany,
    },

    mixins: [DownloadFile],

    data() {
        return {
            request: {
                bank: null,
                account: null,
            },

            anima: { data: [] },
            ofx: { data: [] },
            conciliated: { conciliations: [] },
            availableFiles: {},
            banks: null,
        };
    },

    async mounted() {
        this.initLoad();

        const { data } = await BankService.index();
        this.banks = data;
    },

    methods: {
        async initLoad() {
            await this.getFiles();
            await this.getAvailableFiles();
        },
        async getAvailableFiles() {
            const { data } = await ConciliationService.check();

            const { anima, ofx } = this.groupBy(data.map(item => ({ ...item, table: item.table.toLowerCase() })), 'table');

            this.availableFiles = { anima, ofx };
        },
        async getAllConciliations() {
            this.callLoading(true);
            const promises = [
                this.getAnimaData(),
                this.getOfxData(),
                this.getConciliation(),
            ];

            const responsePromises = await Promise.all(promises);

            const requestHasResolvedWithErrors = !!responsePromises.find(response => response.status !== 200);

            if (requestHasResolvedWithErrors) {
                this.error('Error');
            }

            this.callLoading(false);
        },

        async autoConciliation() {
            const isConfirm = await this.confirmAnAction('Deseja realmente realizar a conciliação automática?');

            if (!isConfirm) return;

            this.callLoading(true);
            const { status } = await ConciliationAnimaService.auto({
                animaId: this.request.animaFileId, ofxId: this.request.ofxFileId,
            });

            if (status !== 200) return;

            await this.getAllConciliations();
            this.callLoading(false);

            this.modalSuccess('Conciliação automática realizada com sucesso');
        },

        async generateData(promise, key, typeId) {
            const { data, status } = await promise();

            if (data) { data.data = data.data.map(item => ({ ...item, type_id: typeId, value: item.lanc_value || item.value })); }

            this[key] = data || { data: [] };

            return { data, status };
        },

        async getOfxData() {
            const { data, status } = await this.generateData(async () => ConciliationOfxService.show({ id: this.request.ofxFileId }), 'ofx', types.ofx);
            return { data, status };
        },

        async getAnimaData() {
            const { data, status } = await this.generateData(async () => ConciliationAnimaService.show({ id: this.request.animaFileId }), 'anima', 1, types.anima);
            return { data, status };
        },

        async getFiles() {
            const { data } = await ConciliationService.check();

            const typesByTable = this.groupBy(data, 'table');

            this.animaFiles = typesByTable.anima;
            this.ofxFiles = typesByTable.ofx;
        },

        async conciliate() {
            const isConfirm = await this.confirmAnAction('Deseja realmente conciliar esses itens?');

            if (!isConfirm) return;
            this.callLoading(true);
            const { anima, ofx } = this;

            const mapData = arr => arr.filter(({ confirm }) => confirm).map(({ id }) => id);

            const { status } = await ConciliationService.create({
                ofx: mapData(ofx.data),
                anima: mapData(anima.data),
            });

            if (status === 200) {
                await this.getAllConciliations();
            } else if (status === 405) {
                this.modalError('Deve-se adicionar o departamento nos dados');
            }

            this.callLoading(false);
        },

        async getConciliation() {
            this.callLoading(true);
            const { data, status } = await ConciliationService.show({
                animaId: this.request.animaFileId,
                ofxId: this.request.ofxFileId,
            });
            this.callLoading(false);

            if (status === 200) {
                data.data.conciliations = data.data.conciliations.map(({ conciliated, ...item }) => ({
                    ...item,
                    conciliated: !!conciliated,
                }));
                this.conciliated = data.data;
            } else {
                this.error('Não foi possível encontrar dados com esses paramêtros nas tabelas');
            }
            return { data, status };
        },

        async completedFile() {
            const { data: { data } } = await ConciliationService.showCompleted(this.request);

            this.generateFile(data, {
                ofx: 'Conciliações Bancárias Realizadas',
                anima: 'Conciliações Anima Realizadas',
            }, 'Dados Conciliados.xlsx');
        },

        async uncompletedFile() {
            const { data: { data } } = await ConciliationService.showUncompleted(this.request);

            this.generateFile(data, {
                ofx: 'Pendências Bancárias',
                anima: 'Pendências Anima',
            }, 'Dados Não Conciliados.xlsx');
        },

        async finish() {
            const isConfirmed = await this.confirmAnAction('Deseja realmente finalizar a conciliação?');

            if (!isConfirmed) return;

            this.callLoading(true);

            const { files } = this.conciliated;

            const { status } = await ConciliationService.complete({
                ofxId: files.ofx_file_id,
                animaId: files.anima_file_id,
            });

            this.callLoading(false);

            if (status === 200) {
                this.modalSuccess('Conciliação finalizada com sucesso');
                await new Promise(resolve => setTimeout(resolve, 1750));
                this.$router.push({
                    path: '/conciliacao/finalizada',
                    query: {
                        animaId: this.conciliated.files.anima_file_id,
                        ofxId: this.conciliated.files.ofx_file_id,
                    },
                });
            } else this.modalError('Erro ao finalizar conciliação');
        },

        validateDepartment(arr) {
            return !arr.find(item => !item.departmentId);
        },

        validateAutoConciliation() {
            return (this.validateDepartment(this.anima.data) && this.validateDepartment(this.ofx.data));
        },

        filter(data) {
            return data.filter(({ confirm }) => confirm);
        },

        validateConciliation() {
            return (this.validateDepartment(
                this.filter(this.anima.data),
            ) && this.validateDepartment(this.filter(this.ofx.data)));
        },

        showUpdateMany() {
            this.$refs.updateMany.showModal();
        },

        deletedFile(success) {
            this.getAllConciliations();

            if (!success) this.modalError('Não foi possível apagar o arquivo pois existem lançamentos conciliados');
            else this.modalSuccess('Arquivo apagado com sucesso');
        },
    },
};
</script>

<style lang="scss">
.conciliate-button {
  min-width: 14rem;
  margin-left: 1rem !important;
  margin-right: 1rem !important;
}
#modal-scrollable {
  .modal-footer {
    display: none;
  }
}
</style>
