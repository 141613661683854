<template>
  <div>
    <ConciliationTable
      :title="title"
      :fields="fields"
      :items="data.data"
      @check-all="checkAll"
      @finish-update="$emit('finish-update')"
    >
      <template v-slot:summary>
        <Summary
          :total-balance-value="+data.finalBalanceValue"
          :total-not-conciliated="+data.totalNotConciliated"
          :balance="+data.totalInitialValue"
          :total-selected="totalSelectedValue"
        />
      </template>

      <template v-slot:footer>
        <div class="d-flex justify-content-between align-item-center">
          <small class="align-middle"> Nome do Arquivo: {{ data.fileName }}</small>
          <b-button
            variant="danger"
            @click="() => $emit('delete-file', data.fileId)"
          >
            Apagar Arquivo
          </b-button>
        </div>
      </template>
    </ConciliationTable>
  </div>
</template>

<script>
import { BButton } from 'bootstrap-vue';
import ConciliationTable from '../Table/Table.vue';
import Summary from '../Table/Summary.vue';

export default {
    components: {
        ConciliationTable,
        Summary,
        BButton,
    },

    props: {
        data: {
            type: Object,
            default: () => ({ data: [] }),
        },

        fields: {
            type: Array,
            required: true,
        },

        title: {
            type: String,
            default: () => '',
        },

        canRemove: {
            type: Boolean,
            default: () => false,
        },
    },

    computed: {
        totalSelectedValue() {
            return this.data.data.reduce((acc, item) => {
                if (item.confirm) {
                    return acc + +item.value;
                }

                return acc;
            }, 0);
        },

        totalInitialValue() {
            return this.data.data.balanceAnima || this.data.data.balanceBank;
        },
    },

    methods: {
        checkAll(value) {
            this.data.data = this.data.data.map(item => ({ ...item, confirm: value }));
        },
    },
};
</script>
