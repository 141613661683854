<template>
  <b-row
    style="gap: 22px"
    class="justify-content-center conciliate"
  >
    <b-button
      variant="primary"
      :disabled="disabledAutoConciliation"
      @click="$emit('auto-conciliation')"
    >
      Conciliar Automaticamente
    </b-button>

    <b-button
      variant="primary"
      :disabled="disabledConciliation"
      @click="$emit('conciliation')"
    >
      Conciliar
    </b-button>

    <b-button
      variant="primary"
      @click="$emit('update-many')"
    >
      Atualizar Vários
    </b-button>

    <b-button
      variant="primary"
      :disabled="disabledFinish"
      @click="$emit('finish')"
    >
      Finalizar
    </b-button>
  </b-row>
</template>

<script>
import { BRow, BButton } from 'bootstrap-vue';

export default {
    components: {
        BRow,
        BButton,
    },

    props: {
        disabledFinish: { type: Boolean, default: () => false },
        disabledAutoConciliation: { type: Boolean, default: () => false },
        disabledConciliation: { type: Boolean, default: () => false },
    },
};
</script>

<style scoped>
.conciliate button {
  min-width: 14rem;
}
</style>
