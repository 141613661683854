/* eslint-disable no-underscore-dangle */
import { GenerateXlsx } from './generate-xlsx';

const DEFAULT_END_LINES = 2;

export class GenerateFileReportDataXlsx extends GenerateXlsx {
    insertHeader() {
        super.insertLineStyled({
            firstColumn: 'Filial',
            secondColumn: 'C/C',
            thirdColumn: 'DATA',
            fourthColumn: 'EVENTO',
            fifthColumn: 'COMPLEMENTO',
            sixthColumn: 'VALOR',
            seventhColumn: 'COD. LANÇAMENTO',
            eighthColumn: 'NOME DO EVENTO',
            ninthColumn: 'DESCRIÇÃO',
        }, {
            bold: true,
            horizontalAlignment: 'center',
            verticalAlignment: 'center',
        });
    }

    insertFilialHeader({ filial, bank, bankName }) {
        super.insertLineStyled({
            firstColumn: 'Filial',
            secondColumn: filial,
            thirdColumn: 'Banco',
            fourthColumn: bank,
            fifthColumn: 'Nome do Banco',
            sixthColumn: bankName,
        }, {
            bold: true,
            fontSize: 11,
        });
    }

    insertFilialData(data) {
        const [firstData] = data;
        this.insertFilialHeader(firstData);
        this.insertHeader();
        data.forEach(item => super.insertLine({
            firstColumn: item.filial,
            secondColumn: item.bank,
            thirdColumn: item.date,
            fourthColumn: item.event,
            fifthColumn: item.complement,
            sixthColumn: item.value,
            seventhColumn: item.lancCode,
            eighthColumn: item.event,
            ninthColumn: item.comments,
        }));
        super.insertBlankLine();
        super.insertBlankLine();
    }

    emptyFile() {
        super._fileContent = [];
    }

    insertTitle(
        title,
    ) {
        super.insertLineStyled({
            firstColumn: title,
        }, {
            fontSize: 11,
            bold: true,
        });
    }

    finish() {
        for (let i = 0; i < DEFAULT_END_LINES; i += 1) {
            super.insertBlankLine();
        }
    }
}
