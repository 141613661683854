<template>
  <div>
    <p
      v-for="(item, key, index) in fields"
      :key="index"
    >
      {{ key }}: <span :class=" item < 0 && 'text-danger'">
        {{ item | value }}
      </span>
    </p>
  </div>
</template>

<script>
export default {
    props: {
        balance: {
            required: true,
            type: Number,
        },

        totalNotConciliated: {
            required: true,
            type: Number,
        },

        totalBalanceValue: {
            required: true,
            type: Number,
        },

        totalSelected: {
            required: true,
            type: Number,
        },
    },

    computed: {
        fields() {
            return {
                'Saldo Inicial': this.balance,
                'Total Não Conciliado': this.totalNotConciliated,
                'Saldo Final': this.totalBalanceValue,
                'Total Selecionado': this.totalSelected,
            };
        },
    },
};
</script>
